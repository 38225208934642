import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ephys/Development/reworkjs/core/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "interacting-with-browser-data"
    }}>{`Interacting with browser data`}</h2>
    <p>{`-> cookies, react-cookie
-> useUserAgent
-> useLocation
-> useDnt
-> useUserLanguages, UserLanguagesContext
-> i18n:
--> isTranslationSupported
--> onIntlHotReload
--> `}<inlineCode parentName="p">{`[locale, setLocale] = useActiveLocale()`}</inlineCode>{`
--> ActiveLocaleContext
-> argv
-> babel-preset`}</p>
    <ul>
      <li parentName="ul">{`useReactRouter`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`process.env.SIDE`}</inlineCode>{`, `}<inlineCode parentName="li">{`process.env.NODE_ENV`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "ssr"
    }}>{`SSR`}</h2>
    <ul>
      <li parentName="ul">{`usePersistentValue`}</li>
      <li parentName="ul">{`useAsyncResource`}</li>
      <li parentName="ul">{`useRes, useReq, SsrContext`}</li>
      <li parentName="ul">{`useHttpStatus(404)`}</li>
      <li parentName="ul">{`HttpStatus`}</li>
    </ul>
    <h2 {...{
      "id": "service-worker"
    }}>{`Service Worker`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`useRestartRequired`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "utils"
    }}>{`Utils`}</h2>
    <p>{`-> withContext
-> logger.js`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      